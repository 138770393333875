.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profile > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}