
.paynewdetsec{
    /* background: #f9f9f9;*/
     /*padding: 20px;*/
     border-radius: 5px;
     margin-bottom: 30px;
    /* border: 1px solid #f0f0f0;*/
 }
 .paynewdetsec label{
     font-weight: 500;
 }
 .paynewdetsec .subbtn{
     font-size: 14px;
     border: none;
 }
 .paynewdetsec .subbtn{
     box-shadow: none;
     border: none;
     outline: none;
 }
 .paynewdetsectran{
     /*background: #f9f9f9;*/
     /*padding: 20px;*/
     border-radius: 5px;
     /*border: 1px solid #f0f0f0;*/
 }
 .paynewdetsectran table {
   border-collapse: collapse;
   width: 100%;
 }
 .paynewdetsectran td, th {
   text-align: left;
   padding: 8px;
 }
 .paynewdetsectran th{
     background: linear-gradient(to bottom, #777777 0%,#444444 100%) !important;
     color: #fff;
     font-weight: normal !important;
 }
 .paynewdetsectran tr:nth-child(even) {
   background-color: #ececec;
 }
 .paynewdetsectran .titletranshist{
     font-size: 18px;
     margin-top: 5px;
     margin-bottom: 15px;
 }
 .divider{
     border-top: 1px solid #ddd;
     padding: 15px 0px;
     margin-top: 10px;
 }
