@tailwind base;
@tailwind components;
@tailwind utilities;

  html {
    font-family: Arial,sans-serif;
    font-size: 14px;
  }
  /* Customer List */
  .customerList {
    display: flex;
    width: 100vw;
    height: 10vh;
    flex-direction: column;
  }

  .customerList > div:nth-child(1) {
    flex: 0.2;
    background-color: #F7F7F7;
  }

  .customerList > div:nth-child(2) {
    flex: 0.8;
    background-color: white;
  }
  @media print{
  .page-break {
    page-break-before: always;
  }}
  
  /* Header */
  .header {
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    background-color: white;
    position: relative;
  }

  .header > div > img {
    width: 25vh;
    height: 6vh;
    margin: 2vh 0;
    object-fit: cover;
  }

  .header >div:nth-child(1) {
    flex: 0.3;
    margin: 0 20px;
    font-size: x-large;
    font-weight: 600;
    color: #5398FE;
  }

  .header >div:nth-child(2) {
    flex: 0.4;
    background-color: #F9FAFB;
    padding: 15px;
    border-radius: 999px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px inset;
    transition: transform 0.2s linear;
  }

  .header > div:nth-child(2):hover {
    transform: scale(1.01);
  }

  .header >div:nth-child(2) > i {
    color: #83a9d4;
  }

  .header >div:nth-child(2) > input {
    border: none;
    width: 100%;
    outline: none;
    margin: 0 5px;
    background-color: #F9FAFB;
  }

  .header > div:nth-child(2) >input::placeholder {
    color: #6da3e0;
    font-size: 14px;
    font-weight: 500;
  }

  .header >div:nth-child(3) {
    flex: 0.3;
    text-align: right;
    margin-right: 3vw;
    position: relative;
  }

  .header >div:nth-child(3) > span > div{
    border: none;
    outline: none;
  }

  .text-icon-big {
    background: linear-gradient(to bottom,
        rgba(73, 155, 234, 1) 0%,
        rgba(32, 124, 229, 1) 100%);
    height: 48px;
    width: 48px;
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    outline: none;
    display: inline-block;
    text-align: center;
  }

  .open-profilepic-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1000;
    right: 0;
    width: 180px;
  }
  
  .open-profilepic-menu > div:nth-child(2){
    display: flex;
  }

  .open-profilepic-menu > p{
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;
  }

  .btn-outline-primary {
    border-radius: 3px;
  }

  .btn-outline-primary:hover {
    background-color: #2A83E6;
    color: white;
  }

  i.cfa-rounded-circle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 8px #3f6289;
    padding: 8px 8px;
    margin-bottom: 10px;
    height: 36px;
    width: 36px;
    margin-right: 8px;
    background-color: white;
  }

  /* Customers */
  .customers {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 10px;
    flex: 0.8;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    overflow: scroll;
    margin-top: 10px;
    height: 90vh;
  }

  .customers > div:nth-child(1) {
    flex: 0.10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
  }
  
  .customers > div:nth-child(2) {
    flex: 0.75;
    overflow: scroll;
  }

  .customers > div:nth-child(3) {
    flex: 0.15;
  }

  .payexpdroplist {
    font-size: 12px;
    text-align: right;
    right: 0;
    margin-top: 5px;
}

.payexpdroplist::before {
    right: 9px;
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    position: absolute;
    display: inline-block;
    content: '';
}

.payexpdroplist::after {
    right: 10px;
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    position: absolute;
    display: inline-block;
    content: '';
}

.payexpdroplist a {
    font-size: 12px;
    text-align: right;
    color: #222;
    padding: 8px 12px;
    display: block;
}

.payexpdroplist a:hover {
    text-decoration: none;
    background: none;
    color: initial;
}


  div.ReactTable {
    border: none;
    background-color: none;
  }
  div.ReactTable .-loading.-active {
    z-index: 0;
  }
  div.ReactTable .-loading{
    display: none;
  }
  div.ReactTable .rt-noData {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -150px;
  }
  div.ReactTable .rt-tbody {
    min-height: 200px !important;
    padding: 5px;
  }
  div.ReactTable .rt-thead {
    font-size: 14px;
    /* color: #809FE4; */
    /* color: #9099E4; */
    color: white;
    line-height: 1.6;
    /* background-color: #F7F7F7; */
    
    background: linear-gradient(180deg,#777 0,rgb(102, 101, 101))!important;
    padding: 12px 0;
    border-radius: 5px;
  }
  div.ReactTable .rt-thead.-header {
    box-shadow: none;
  }
  div.ReactTable .rt-tr {
    display: flex;
  }
  div.ReactTable .rt-thead div.rt-th {
    font-weight: 400;
    text-align: left;
    border-right: none;
  }
  div.ReactTable .rt-thead div.rt-th:first-child {
    padding-left: 20px;
  }
  div.ReactTable .rt-thead div.rt-th.-sort-asc,
  div.ReactTable .rt-thead div.rt-th.-sort-desc {
    box-shadow: none;
  }
  div.ReactTable .rt-thead .rt-resizable-header .sort-icons {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
  }
  div.ReactTable .rt-thead .rt-resizable-header .sort-icons .fas {
    display: block;
    font-size: 16px;
    line-height: 8px;
  }
  div.ReactTable
    .rt-thead
    .rt-resizable-header.-sort-asc
    .sort-icons
    .fa-caret-down {
    color: #a9a9a9;
  }
  div.ReactTable
    .rt-thead
    .rt-resizable-header.-sort-desc
    .sort-icons
    .fa-caret-up {
    color: #a9a9a9;
  }
  div.ReactTable .rt-tr-group {
    padding: 12px 0px;
    -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
    position: relative;
    color: #333;
    cursor: pointer;
    font-size: 13px;
  }
  div.ReactTable .rt-tr-group a {
    text-decoration: none;
    color: #333;
  }
  div.ReactTable .rt-tr-group:hover {
    background: #f9f9f9;
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
      0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: 0px 2px 5px 0px #dadce0;
    color: #333;
  }

  /* .sort-icons {
    display: flex;
    flex-direction: column;
  } */

  .rt-resizable-header-content > span {
    font-weight: 600;
  }
  
  .rt-td > span{
    /* color: #808D99; */
    font-weight: 400;
  }

  /* Graphs */
  .donutchart-legend-dot {
    color: #fff;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0px 3px -5px 0px;
  }

  .dropdown-wrap .form-group {
    display: inline-block;
    width: 100%;
  }
  
  .dropdown-menu.show {
    z-index: 100;
  }
  .dropdown-wrap .dropdown-menu {
    z-index: 5;
  }

  /*react-bootstrap*/
  .no-arrow.dropdown-toggle:after {
    content: none !important;
  }

  @media (max-width: 900px) {
    .dropdown-menu.notification-tray {
      width: 95%;
    }
    .mobile-padding-top {
      margin-top: 80px;
    }
  }
  
  .dropdown-menu.notification-tray {
    width: 400px;
    right: 11px;
    top: 74px;
    box-shadow: -1px 1px 2px #dfdcdc;
  }

  .btn-primary {
    border-radius: 3px;
    background: linear-gradient(to bottom,
        rgba(73, 155, 234, 1) 0%,
        rgba(32, 124, 229, 1) 100%) !important;
  }

  .static_height .form-group {
    height: 60px !important;
  }

  .form-error {
    color: #f30909;
    font-size: 12px;
  }

  .invoiceTemplate {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    height: fit-content;
    /* width: 80%; */
    /* border: 1px solid lightgray; */
    border-radius: 3px;
  }

  /* Client Section */

  .clientSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .clientSection > div:nth-child(1) > img{
    object-fit: cover;
  }
  .clientSection > div:nth-child(1){
    flex: 0.4;
  }
  .clientSection > div:nth-child(2){
    flex: 0.25;
    overflow-wrap: break-word;
  }
  /* Client Section Ends */

  /* Customer Section */

  .customerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.2;
    overflow-wrap: break-word;
  }

  .customerSection > div:nth-child(1) {
    flex: 0.4;
  }

  .customerSection > div:nth-child(2) {
    flex: 0.25;
  }

  /* Customer Section Ends */

  /* Line Items Section */

  table, th {
    border: 2px solid black;
  }

  /* .lineItemsSection {
    overflow: scroll;
  } */
  *, *:after, *:before {
    /* box-sizing: border-box; */
    -webkit-box-sizing: border-box;
  }

   .lineItemsSection > table > tbody > tr {
    border-bottom: 1.5px solid black;
  }

  .lineItemsSection > table > tbody > tr > td {
    border-left: 1.5px solid black;
  }
  
  .lineItemsSection > table > thead > tr > td,.taxesSection > div > table{
    /* width: 800px; */
    font-family: "Trebuchet MS";
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 1px 1px;
    background-color: #ffffff;
    font-weight: bold;
    height: 20px;
    vertical-align: middle;
    text-align: center;
    position: relative;
    padding-left: 0;
    overflow-wrap: break-word;
    max-width: 150px;
    border-left: 1.5px solid black;
    border-top: 0.5px solid black;
    border-bottom: 1.5px solid black;
  }
  .lineItemsSection > table > tbody > tr > td{
    /* width: 800px; */
    font-family: "Open Sans";
    font-size: 14px;
    border-collapse: collapse;
    border-spacing: 1px 1px;
    background-color: #ffffff;
    height: 20px;
    vertical-align: middle;
    text-align: center;
    position: relative;
    padding-left: 0;
    overflow-wrap: break-word;
    max-width: 150px;
    /* border-left: 1px solid black; */
  }

  .lineItemsSection > table > tbody :last-child{
    /* border-right: 1px solid black; */
  }

  .lineItemsSection > table > tbody > tr:last-child {
    /* border-bottom: 1px solid black; */
  }

  .footerSection > div > table > thead{
    /* width: 800px; */
    font-family: "Trebuchet MS";
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 1px 1px;
    background-color: #ffffff;
    text-align: center;
    position: relative;
    padding-left: 0;
    overflow-wrap: break-word;
    max-width: 150px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
  }

  .footerSection > div > table > thead > tr > td{
    /* width: 800px; */
    font-family: "Trebuchet MS";
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 1px 1px;
    background-color: #ffffff;
    font-weight: bold;
    height: 10px;
    vertical-align: middle;
    text-align: center;
    position: relative;
    padding-left: 0;
    overflow-wrap: break-word;
    max-width: 150px;
    border-bottom: 1.5px solid black;
  }

  .footerSection > div > table > tbody{
    /* width: 800px; */
    font-family: "Trebuchet MS";
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 1px 1px;
    background-color: #ffffff;
    height: 20px;
    vertical-align: middle;
    position: relative;
    text-align: left;
    padding-left: 0;
    overflow-wrap: break-word;
    max-width: 150px;
    /* border : 1.5px solid black; */
  } 
  .footerSection > div > table > tbody > tr > td{
    /* width: 800px; */
    font-family: "Trebuchet MS";
    font-size: 12px;
    background-color: #ffffff;
    height: 20px;
    /* vertical-align: auto; */
    position: relative;
    text-align: left;
    padding-left: 0;
    overflow-wrap: break-word;
    max-width: 150px;
  } 

    thead {
      background-color: #EDF2FE;
    }

    thead > tr > td {
      font-weight: 600;
    }    

    td {
      height: 50px;
      vertical-align: middle;
      text-align: left;
      position: relative;
      padding-left: 20px;
      overflow-wrap: break-word;
      max-width: 150px;
    }

    td:last-child {
      text-align: right;
      padding-right: 20px;
    }

    td::after {
      content: '';
      width: 1px;
      position: absolute;
      right: 0;
      top: 8px;
      bottom: 8px;
      background-color: #eee;
    }

    td:last-child::after {
      content: none;
    }

    .tbody > tr > td{
      border: none;
    }

    tbody > td:first-child {
      /* border-left: 1px solid #eee; */
    }

    tbody > td:last-child {
      /* border-left: 1px solid #eee; */
    }

    tbody > tr {
      background-color: #fafafa;
    }

    .ReactTable .expand-row .expandable {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .ReactTable .expand-row {
      position: relative;
    }
    
    .ReactTable .expand-row .expandable {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: black;
      font-size: large;
      margin-left: 20px;
    }

    /* Line Items Section Ends*/

    /* Tax Section */
    /* .taxesSection {
      display: flex;
      justify-content: end;
    } */
  
    /* Tax Section End*/
    
    .filelist-wrapper {
      height: 150px;
      overflow-y: auto;
    }
    
    .filelist-wrapper .card {
      display: block;
      height: 140px;
    }

    .text-link {
      color: #2a7fff !important;
    }
    
    .text-cf-icons {
      color: #007bff !important;
    }
    
    .text-link:hover {
      text-decoration: underline;
    }

    .file-wrapper {
      padding: 10px;
    }

    .invoice-file-size {
      float: right;
    }
  
    .invoice-file-name {
        text-transform: uppercase;
    }

    .emailthumbnail.card {
      margin: 5px;
    }

    /* Invoice List */
    .invoiceList {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 10px;
    }

    .invoiceList > .charts > div{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .invoiceList > .list > div:nth-child(2){
      overflow: scroll;
    }

    .setbtn {
      float: left;
      width: 100%;
      margin-top: 30px;
    }
    
    .setbtn.box-space {
      margin-top: 5px;
    }
    
    .setbtn .resetbtn,
    .setbtn .resetbtn:hover,
    .setbtn .resetbtn:focus {
      background: #e2e2e2;
      color: #222;
      font-size: 14px;
      width: 48%;
      text-align: center;
      float: left;
      border-radius: 0.25rem;
    }
    
    .setbtn .applybtn,
    .setbtn .applybtn:hover,
    .setbtn .applybtn:focus {
      color: #fff;
      width: 48%;
      background: #2a7fff;
      text-align: center;
      font-size: 14px;
      float: right;
      border-radius: 0.25rem;
    }
    
    .invoiceTitle {
      font-size: 20px;
      cursor: pointer;
      font-weight: 500;
      margin: 10px;
      color: rgb(91, 87, 87);
    }

    .invoiceTitle > span > i{
      font-weight: normal;
    }

    .shimmerEffect {
      box-shadow: none;
    }

    .fa-arrow-left:before {
      content: "\f060";
    }

    .cursor-pointer{
      cursor: pointer;
    }

    .searchList {
      position: absolute;
      z-index: 999;
      width: 100%;
      top: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .searchList > div {
      background-color: white;
      width: 30vw;
      border-radius: 5px;
      max-height: 40vh;
      overflow: scroll;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
    .searchList > div > div{
      display: flex;
      cursor: pointer;
      align-items: center;
      padding: 10px;
      transition: all 0.2s ease-in-out;
    }
    .loading {
      justify-content: center;
      margin-left: 20px;
    }
    .searchList > div > div:hover{
      background-color: rgb(240, 240, 240);
    }
    .searchList > div > div > i{
      font-size: larger;
      color: #2588f2;
    }
    .searchList > div > div > div{
      font-weight: 500;
    }

    .dot-pulse {
      position: relative;
      left: -9999px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #2588f2;
      color: #2588f2;
      box-shadow: 9999px 0 0 -5px;
      animation: dot-pulse 1.5s infinite linear;
      animation-delay: 0.25s;
    }
    .dot-pulse::before, .dot-pulse::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #2588f2;
      color: #2588f2;
    }
    .dot-pulse::before {
      box-shadow: 9984px 0 0 -5px;
      animation: dot-pulse-before 1.5s infinite linear;
      animation-delay: 0s;
    }
    .dot-pulse::after {
      box-shadow: 10014px 0 0 -5px;
      animation: dot-pulse-after 1.5s infinite linear;
      animation-delay: 0.5s;
    }
    
    @keyframes dot-pulse-before {
      0% {
        box-shadow: 9984px 0 0 -5px;
      }
      30% {
        box-shadow: 9984px 0 0 2px;
      }
      60%, 100% {
        box-shadow: 9984px 0 0 -5px;
      }
    }
    @keyframes dot-pulse {
      0% {
        box-shadow: 9999px 0 0 -5px;
      }
      30% {
        box-shadow: 9999px 0 0 2px;
      }
      60%, 100% {
        box-shadow: 9999px 0 0 -5px;
      }
    }
    @keyframes dot-pulse-after {
      0% {
        box-shadow: 10014px 0 0 -5px;
      }
      30% {
        box-shadow: 10014px 0 0 2px;
      }
      60%, 100% {
        box-shadow: 10014px 0 0 -5px;
      }
    }
    